import _functionApply from "../internals/function-apply";
import _functionCall from "../internals/function-call";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _fixRegexpWellKnownSymbolLogic from "../internals/fix-regexp-well-known-symbol-logic";
import _fails from "../internals/fails";
import _anObject from "../internals/an-object";
import _isCallable from "../internals/is-callable";
import _isNullOrUndefined from "../internals/is-null-or-undefined";
import _toIntegerOrInfinity from "../internals/to-integer-or-infinity";
import _toLength from "../internals/to-length";
import _toString from "../internals/to-string";
import _requireObjectCoercible from "../internals/require-object-coercible";
import _advanceStringIndex from "../internals/advance-string-index";
import _getMethod from "../internals/get-method";
import _getSubstitution from "../internals/get-substitution";
import _regexpExecAbstract from "../internals/regexp-exec-abstract";
import _wellKnownSymbol from "../internals/well-known-symbol";
var apply = _functionApply;
var call = _functionCall;
var uncurryThis = _functionUncurryThis;
var fixRegExpWellKnownSymbolLogic = _fixRegexpWellKnownSymbolLogic;
var fails = _fails;
var anObject = _anObject;
var isCallable = _isCallable;
var isNullOrUndefined = _isNullOrUndefined;
var toIntegerOrInfinity = _toIntegerOrInfinity;
var toLength = _toLength;
var toString = _toString;
var requireObjectCoercible = _requireObjectCoercible;
var advanceStringIndex = _advanceStringIndex;
var getMethod = _getMethod;
var getSubstitution = _getSubstitution;
var regExpExec = _regexpExecAbstract;
var wellKnownSymbol = _wellKnownSymbol;
var REPLACE = wellKnownSymbol("replace");
var max = Math.max;
var min = Math.min;
var concat = uncurryThis([].concat);
var push = uncurryThis([].push);
var stringIndexOf = uncurryThis("".indexOf);
var stringSlice = uncurryThis("".slice);

var maybeToString = function (it) {
  return it === undefined ? it : String(it);
}; // IE <= 11 replaces $0 with the whole match, as if it was $&
// https://stackoverflow.com/questions/6024666/getting-ie-to-replace-a-regex-with-the-literal-string-0


var REPLACE_KEEPS_$0 = function () {
  // eslint-disable-next-line regexp/prefer-escape-replacement-dollar-char -- required for testing
  return "a".replace(/./, "$0") === "$0";
}(); // Safari <= 13.0.3(?) substitutes nth capture where n>m with an empty string


var REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE = function () {
  if (/./[REPLACE]) {
    return /./[REPLACE]("a", "$0") === "";
  }

  return false;
}();

var REPLACE_SUPPORTS_NAMED_GROUPS = !fails(function () {
  var re = /./;

  re.exec = function () {
    var result = [];
    result.groups = {
      a: "7"
    };
    return result;
  }; // eslint-disable-next-line regexp/no-useless-dollar-replacements -- false positive


  return "".replace(re, "$<a>") !== "7";
}); // @@replace logic

fixRegExpWellKnownSymbolLogic("replace", function (_, nativeReplace, maybeCallNative) {
  var UNSAFE_SUBSTITUTE = REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE ? "$" : "$0";
  return [// `String.prototype.replace` method
  // https://tc39.es/ecma262/#sec-string.prototype.replace
  function replace(searchValue, replaceValue) {
    var O = requireObjectCoercible(this);
    var replacer = isNullOrUndefined(searchValue) ? undefined : getMethod(searchValue, REPLACE);
    return replacer ? call(replacer, searchValue, O, replaceValue) : call(nativeReplace, toString(O), searchValue, replaceValue);
  }, // `RegExp.prototype[@@replace]` method
  // https://tc39.es/ecma262/#sec-regexp.prototype-@@replace
  function (string, replaceValue) {
    var rx = anObject(this);
    var S = toString(string);

    if (typeof replaceValue == "string" && stringIndexOf(replaceValue, UNSAFE_SUBSTITUTE) === -1 && stringIndexOf(replaceValue, "$<") === -1) {
      var res = maybeCallNative(nativeReplace, rx, S, replaceValue);
      if (res.done) return res.value;
    }

    var functionalReplace = isCallable(replaceValue);
    if (!functionalReplace) replaceValue = toString(replaceValue);
    var global = rx.global;

    if (global) {
      var fullUnicode = rx.unicode;
      rx.lastIndex = 0;
    }

    var results = [];

    while (true) {
      var result = regExpExec(rx, S);
      if (result === null) break;
      push(results, result);
      if (!global) break;
      var matchStr = toString(result[0]);
      if (matchStr === "") rx.lastIndex = advanceStringIndex(S, toLength(rx.lastIndex), fullUnicode);
    }

    var accumulatedResult = "";
    var nextSourcePosition = 0;

    for (var i = 0; i < results.length; i++) {
      result = results[i];
      var matched = toString(result[0]);
      var position = max(min(toIntegerOrInfinity(result.index), S.length), 0);
      var captures = []; // NOTE: This is equivalent to
      //   captures = result.slice(1).map(maybeToString)
      // but for some reason `nativeSlice.call(result, 1, result.length)` (called in
      // the slice polyfill when slicing native arrays) "doesn't work" in safari 9 and
      // causes a crash (https://pastebin.com/N21QzeQA) when trying to debug it.

      for (var j = 1; j < result.length; j++) push(captures, maybeToString(result[j]));

      var namedCaptures = result.groups;

      if (functionalReplace) {
        var replacerArgs = concat([matched], captures, position, S);
        if (namedCaptures !== undefined) push(replacerArgs, namedCaptures);
        var replacement = toString(apply(replaceValue, undefined, replacerArgs));
      } else {
        replacement = getSubstitution(matched, S, position, captures, namedCaptures, replaceValue);
      }

      if (position >= nextSourcePosition) {
        accumulatedResult += stringSlice(S, nextSourcePosition, position) + replacement;
        nextSourcePosition = position + matched.length;
      }
    }

    return accumulatedResult + stringSlice(S, nextSourcePosition);
  }];
}, !REPLACE_SUPPORTS_NAMED_GROUPS || !REPLACE_KEEPS_$0 || REGEXP_REPLACE_SUBSTITUTES_UNDEFINED_CAPTURE);
export default {};